import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const Message = styled.h1`
  font-size: 4rem;
  margin-bottom: 2rem;
`;

const Button = styled.button`
  font-size: 2rem;
  padding: 1rem 2rem;
  border: none;
  background-color: #0077cc;
  color: #fff;
  cursor: pointer;
`;

const NotFoundPage = () => {
    const navigate = useNavigate();

  const handleClick = () => {
    navigate('/');
  };

  return (
    <Container>
      <Message>Sorry, this page does not exist.</Message>
      <Button onClick={handleClick}>Go Back Home</Button>
    </Container>
  );
};

export default NotFoundPage;
