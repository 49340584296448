import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import './App.css';
import NotFoundPage from './first/component/NotFoundPage';
import StudentPage from './first/StudentPage';
import Home, { countryData } from './Home';
import SchoolPage from './second';
import Test from './test';
import ReactGA from 'react-ga';
import { gAnalyticsID } from './api/api';
import IndiaIndex from './second/indiaIndex';
import NewStudentPage from './first/NewStudentPage';

ReactGA.initialize(gAnalyticsID);

function App() {
  return (
    <div>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/test' element={<Test />} />
        <Route path='/india/ext' element={<IndiaIndex />} />
        <Route path='/:countryName/student' element={<CountryStudent />} />
        <Route path='/:countryName/student/:site_id' element={<CountryStudent />} />
        <Route path='/:countryName' element={<CountrySchool />} />
        <Route path='/student/fyp' element={<NewStudentPage />} />
        <Route path='*' element={<NotFoundPage />} />
      </Routes>
    </div>
  );
}

function IterateCountry() {
  const { countryName } = useParams();
  const countryList = countryData
  for (let i = 0; i < countryList.length; i++) {
    if (countryName.toLowerCase() === countryList[i].slug.toLowerCase()) {
      return true;
    }
  }
  return false;
}

function CountrySchool() {
  if (IterateCountry())
    return <SchoolPage />;
  else
    return <NotFoundPage />;

}

function CountryStudent() {
  if (IterateCountry())
    return <StudentPage />;
  else
    return <NotFoundPage />;
}

export default App;
