import { useEffect, useState, useRef } from 'react';
import {
    Button,
    makeStyles,
} from '@material-ui/core';
import OtpInput from 'react-otp-input';
import { Form, InputGroup } from "react-bootstrap";
import Select from "react-select";
import '../../assets/css/index.css'
import { postApiCallStringyfy } from '../../api/api';
import ShowModal from './modal';


import Swal from 'sweetalert2'

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        width: 250,
        borderRadius: 30,
        backgroundColor: "#fff",
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: 'none'
            },
        },
        '@media (max-width: 750px)': {
            width: 300,
        },
    },
    formControlFull: {
        // margin: theme.spacing(1),
        marginTop: 20,
        borderRadius: 30,
        backgroundColor: "#fff",
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: 'none'
            },
        },
        '@media (max-width: 750px)': {
            // width: 300,
        },
    },
    textField: {
        // margin: theme.spacing(1),
        border: 'none',
        borderRadius: 30,
        backgroundColor: "#fff",
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                border: 'none'
            },
        },
        '@media (max-width: 700px)': {
            maxWidth: '100%',
        },
    },
    otpButton: {
        width: '30%',
        fontSize: '15px',
        borderRadius: '87px',
        textTransform: 'capitalize',
        backgroundColor: '#148108',
        // padding: '11px',
        // marginRight: '-10px',
    },

    getOtpButton: {
        width: '100%',
        fontSize: '15px',
        borderRadius: '50px',
        marginLeft: 5,
        textTransform: 'capitalize',
        backgroundColor: '#148108',
        // padding: '11px',
        // marginRight: '-10px',
    },

    submitOtpButton: {
        width: '30%',
        fontSize: '15px',
        borderRadius: '87px',
        textTransform: 'capitalize',
        backgroundColor: '#12B800',
        // color: otpBtnDisable ? "#fff" : "#000",
        padding: '3px',
        marginRight: '10px',
        border: '1px solid white',
        '@media (max-width: 700px)': {
            marginTop: '1rem',
            width: '40%',
        },

        '&:visited': {
            Color: '#fff',
        },
    },
    otpBoxes: {
        width: '30px',
        marginLeft: '15px',
        '@media (max-width: 700px)': {
            // width: '15px',
            // marginLeft: '30px',
        },
    },
    submitButton: {
        width: '30%',
        fontSize: '15px',
        borderRadius: '87px',
        textTransform: 'capitalize',
        backgroundColor: '#FFAF10',
        padding: '3px',
        marginRight: '10px',
        border: '1px solid white',
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    resendButton: {
        backgroundColor: "#118f45",
        fontSize: 13,
        fontWeight: 'bold',
        color: '#fff',
        textTransform: 'capitalize',
        marginTop: 10,
        padding: 8,
        borderRadius: 15,
        '&:hover': {
            backgroundColor: "#FFAF10",
        },
    }
}));

const gradeList = [
    { "value": "1" },
    { "value": "2" },
    { "value": "3" },
    { "value": "4" },
    { "value": "5" },
    { "value": "6" },
    { "value": "7" },
    { "value": "8" },
    { "value": "9" },
    { "value": "10" },
    { "value": "11" },
    { "value": "12" },

];


const NewStudentForm = () => {


    const classes = useStyles();

    const [otp, setOtp] = useState('');    
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');

    const [emailFieldDisable, setEmailFieldDisable] = useState(false);
    // const [otpBtnDisable, setOtpBtnDisable] = useState(false);
    const [getOtpBtnDisability, setGetOtpBtnDisability] = useState(false);
    const [verifiedBtnDisability, setVerifiedBtnDisability] = useState(false);
    const formRef = useRef();

    // timer
    const [countdown, setCountdown] = useState(180); // set initial countdown value to 3 minutes
    const [timerRunning, setTimerRunning] = useState(false); // track whether the timer is running or not

    useEffect(() => {
        getData();
        let intervalId;
        if (timerRunning && countdown > 0) {
            intervalId = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000);
        }
        return () => clearInterval(intervalId);
    }, [timerRunning, countdown]);

    const minutes = Math.floor(countdown / 60);
    const seconds = countdown % 60;

    const [showTimer, setShowTimer] = useState(false)
    const [resendBtn, setResedBtn] = useState(false)

    useEffect(() => {
        if (countdown === 0) {
            setResedBtn(true)
            setCountdown(180)

            setTimerRunning(false);
        }
    }, [countdown]);


    const getData = async () => {
        // for (let i = 0; i < countryList.length; i++) {
        //     if (countryName.toLowerCase() === countryList[i].value.toLowerCase()) {
        //         setCountryCode(countryList[i].id)
        //         const res = await postApiCall('ncl/getStates', "country_id=" + countryList[i].id);
        //         setStateList(res[0]);
        //     }
        // }
        // console.log("details",countryDetails.id);

    }

    const [errorModal, setErrorModal] = useState(false)
    const [title, setTitle] = useState('');
    const [messaage, setMessaage] = useState('');
    const [error, setError] = useState(false);

    const handleSubmit = async (event) => {
        event.preventDefault();

        const formdata = new FormData(formRef.current);
        const entries = Array.from(formdata.entries());
        const formValues = entries.reduce(
            (prev, [name, value]) => ({
                ...prev,
                [name]: value,
            }),
            {}
        );

        const domainRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if (!(domainRegex.test(email))) {
            Swal.fire({
                title: 'Error',
                text: 'Please enter a valid email',
                icon: 'error'
            });
            return;
        }

        if (phone === '') {
            Swal.fire({
                title: 'Error',
                text: 'Please enter the phone number',
                icon: 'error'
            });
            return;

        }

        if (formValues.pin < 100000 || formValues.pin > 999999) {
            Swal.fire({
                title: 'Error',
                text: 'Pin code must be 6 digit number!',
                icon: 'error'
            });
            return;
        }

        // if (data === '1') {
        if (!emailFieldDisable) {
            Swal.fire({
                title: 'Error',
                text: 'Please verify otp',
                icon: 'error'
            });
            return;
        }
        // } else {
        //     if (!otpBtnDisable) {
        //         Swal.fire({
        //             title: 'Error',
        //             text: 'Please verify your Phone Number First',
        //             icon: 'error'
        //         });
        //         return;
        //     }
        // }

        console.log({ ...formValues, mobile: phone, email: email, school: "271" });
        const res = await postApiCallStringyfy('ncl/addNewStudent', { ...formValues, mobile: phone, email: email, school: "271" });
        if (res[0].status === 'error') {

            Swal.fire({
                title: 'Error',
                text: res[0].response.message,
                icon: 'error'
            });
            return;
        }

        setTitle("Your seat is saved")
        setMessaage(res[0].response.message)
        setError(false)
        setErrorModal(true)

        formRef.current.reset();
    };

    const handleSubmitCode = async (e) => {
        e.preventDefault();

        if (otp === '') {
            Swal.fire({
                title: 'Error',
                text: 'Provide OTP first!',
                icon: 'error'
            });
            return;
        }

        const sendData = {
            "otpType": 'email',
            "otpTo": email,
            // "otpType": data !== '1' ? "mobile" : 'email',
            // "otpTo": data !== '1' ? phone : email,
            "otp": otp
        }

        const res = await postApiCallStringyfy('ncl/verifyOtp', sendData);
        if (res[0].status === 'error') {
            Swal.fire({
                title: 'Error',
                text: res[0].response.message,
                icon: 'error'
            });
            return;
        }

        setResedBtn(false)
        setShowTimer(false)

        setVerifiedBtnDisability(true)
    }

    const handleGetOtp = async (e) => {
        e.preventDefault();

        // if (data !== '1') {
        //     if (phone === '') {
        //         Swal.fire({
        //             title: 'Error',
        //             text: 'Please enter the phone number',
        //             icon: 'error'
        //         });
        //         return;
        //     }

        //     if (phone < 1000000000 || phone > 9999999999) {
        //         Swal.fire({
        //             title: 'Error',
        //             text: 'Phone Number must be of 10 digit number',
        //             icon: 'error'
        //         });

        //         return;
        //     }
        // } else {
        if (email === '') {
            Swal.fire({
                title: 'Error',
                text: 'Please enter the Email',
                icon: 'error'
            });
            return;
        }
        const domainRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!(domainRegex.test(email))) {
            Swal.fire({
                title: 'Error',
                text: 'Please enter a valid email',
                icon: 'error'
            });
            return;
        }
        // }

        const sendData = {
            "otpType": "email",
            "otpTo": email
        }

        const res = await postApiCallStringyfy('ncl/requestValidation', sendData);
        if (res[0].status === 'error') {
            Swal.fire({
                title: 'Error',
                text: res[0].response.message,
                icon: 'error'
            });
            return;
        }
        setGetOtpBtnDisability(true);
        setShowTimer(true)
        setResedBtn(false)
        setTimerRunning(true);
        // if (data !== '1')
        //     setOtpBtnDisable(true)
        // else
        setEmailFieldDisable(true)
        Swal.fire({
            title: 'Successful',
            text: res[0].response.message,
            icon: 'success'
        });

    };

    
    // validation for name
    function handleKeyDown(event) {
        const regex = /^[a-zA-Z\s]*$/;
        if (!regex.test(event.key)) {
            event.preventDefault();
        }
    }

    return (
        <div>
            {errorModal && <ShowModal modalOpen={errorModal} setErrorModal={setErrorModal} titile={title} message={messaage} error={error} type="student" />}
            <form onSubmit={handleSubmit} ref={formRef}>
                <div className='form-container'>

                    <Form.Group>
                        <Select
                            placeholder="Grade"
                            name="grade"
                            options={gradeList.map((item) => ({
                                label: item.value,
                                value: item.value,
                            }))}
                            required={true}
                            classNamePrefix="filter-select"
                            className="filterButton"
                            autoComplete="new-password"
                        />

                        <Form.Control.Feedback type="invalid">
                            Please select Your State.
                        </Form.Control.Feedback>
                    </Form.Group>



                    <Form.Control
                        type="text"
                        name="name"
                        placeholder="Full Name"
                        className="font-normal"
                        required={true}
                        onKeyDown={handleKeyDown}
                        autoComplete="new-password"
                    />
                    <Form.Control.Feedback type="invalid">
                        Please enter the Name.
                    </Form.Control.Feedback>

                    <InputGroup>

                        <Form.Control
                            type="number"
                            placeholder="+91"
                            disabled={false}
                            onChange={(event) => setPhone(event.target.value)}
                            classNamePrefix="form-control"
                            required={true}
                            onInput={(e) => {
                                e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 10)
                            }}
                            autoComplete="new-password"
                        />
                        {/* {data !== '1' &&
                            <Form.Text className="text-muted">
                                <Button
                                    disabled={getOtpBtnDisability}
                                    variant="contained"
                                    color="primary"
                                    onClick={handleGetOtp}
                                    className={classes.getOtpButton}
                                >
                                    Get Otp
                                </Button>
                            </Form.Text>
                        } */}
                    </InputGroup>
                    <Form.Control.Feedback type="invalid">
                        Please enter Phone number.
                    </Form.Control.Feedback>


                    <InputGroup>
                        <Form.Control
                            type="email"
                            name="email"
                            placeholder="Email"
                            className="font-normal"
                            disabled={emailFieldDisable}
                            onChange={(event) => setEmail(event.target.value)}
                            autoComplete="new-password"
                        />
                        {/* {data === '1' && */}
                        
                        {/* } */}
                    </InputGroup>
                    <Form.Text className="text-muted">
                        <Button
                            disabled={getOtpBtnDisability}
                            variant="contained"
                            color="primary"
                            onClick={(e) => handleGetOtp(e)}
                            className={`ms-auto ${classes.getOtpButton}`}
                        >
                            Get Otp
                        </Button>
                    </Form.Text>

                    {/* </Form.Group> */}


                    {/* <TextField
                        id="outlined-name"
                        label="+91"
                        name='phone'
                        type="number"
                        required
                        className={classes.textField}
                        inputProps={{
                            max: 10,
                            maxLength: 10,
                            inputMode: 'numeric',
                            min: 10
                        }}
                        InputProps={{
                            endAdornment: (<Button variant="contained"
                                color="primary"
                                disabled={otpBtnDisable}
                                onClick={handleGetOtp}
                                className={classes.otpButton}>
                                Get Otp</Button>)
                        }}
                        onChange={(e) => setPhone(e.target.value)}
                        margin="normal"
                        variant="outlined"
                        fullWidth
                    /> */}

                    <div className='form-rows'>
                        <OtpInput
                            value={otp}
                            onChange={(val) => setOtp(val)}
                            numInputs={6}
                            separator={<span> </span>}
                            className={classes.otpBoxes}
                            inputStyle="test"
                            isInputNum={true}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            disabled={verifiedBtnDisability}
                            onClick={handleSubmitCode}
                            className={classes.submitOtpButton}>
                            {verifiedBtnDisability ? "Verified" : "Verify OTP"}
                        </Button>
                    </div>

                    {showTimer &&
                        <div className='d-flex justify-content-center'>
                            {
                                !resendBtn ? <p className="text-center">
                                    {`${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`}
                                </p> :

                                    <Button className={classes.resendButton} onClick={handleGetOtp}>Resend</Button>
                            }
                        </div>
                    }

                    <div className='submit-button'>
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            className={classes.submitButton}
                        >
                            Submit
                        </Button>
                    </div>
                    <div className='consent-input c-style'>
                        By registering here, I agree to ICode&nbsp;
                        <a href="https://icode.org/terms-of-use/" target='_blank' rel='noreferrer noopener'>
                            Terms & Conditions
                        </a> and&nbsp;
                        <a href="https://icode.org/privacy-policy/" target='_blank' rel='noreferrer noopener'>
                            Privacy Policy
                        </a>
                    </div>

                </div>

            </form>
        </div>
    )
}

export default NewStudentForm