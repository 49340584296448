import React from "react";
import { Grid, Typography } from '@mui/material';
import "./home.css";
import '../../../assets/css/index.css'
import { WorkContainer, workImgStyle, workRowSecondItemStyle, WorkTypographyContainer, workTypoStyle } from "../../../assets/css/styles";

import asian_cute_girl_with_glasses_backpack_sitting_book_with_blue_sky from '../../../assets/images/asian_cute_girl_with_glasses_backpack_sitting_book_with_blue_sky.png'
import card_login from '../../../assets/images/card_login.png'
import card_tech from '../../../assets/images/card_tech.png'
import card_globe from '../../../assets/images/card_globe.png'

const Works = () => {
  return (

    <div>
      <Grid sx={{ backgroundColor: "#2A2F4F", display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', paddingTop: 8, paddingBottom: 8, }} >
        <Typography variant='h4' sx={{ marginBottom: 5, fontWeight: 'bold', color: '#fff' }}>How it works?</Typography>
        <WorkContainer>
          {/* first row */}
          <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', marginTop: 1 }} >
            <Grid item xs={12} sm={6} md={6}>
              <img src={card_login} alt='asian' style={workImgStyle} />
            </Grid>

            <Grid item xs={12} sm={6} md={6} style={workRowSecondItemStyle} >
              <WorkTypographyContainer>
                <Typography variant='p' sx={{ fontWeight: 'bold', color: '#fff', lineHeight: 1.5 }}>Register & self learning module: <br /> <span style={workTypoStyle
                }>Access to self learning modules after registrations</span></Typography>
              </WorkTypographyContainer>
            </Grid>
          </Grid>


          {/* second row */}
          <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: { lg: 'row-reverse', md: 'row-reverse', sm: 'row', xs: 'row' }, marginTop: 1 }} >
            <Grid item xs={12} sm={6} md={6}>
              <img src={card_tech} alt='asian' style={workImgStyle} />
            </Grid>

            <Grid item xs={12} sm={6} md={6} style={workRowSecondItemStyle} >
              <WorkTypographyContainer>
                <Typography variant='p' sx={{ fontWeight: 'bold', color: '#fff', lineHeight: 1.5 }}>Register & self learning module: <br /> <span style={workTypoStyle}>Access to self learning modules after registrations</span></Typography>
              </WorkTypographyContainer>
            </Grid>
          </Grid>

          {/* third row */}
          <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', marginTop: 1 }} >
            <Grid item xs={12} sm={6} md={6}>
              <img src={asian_cute_girl_with_glasses_backpack_sitting_book_with_blue_sky} alt='asian' style={workImgStyle} />
            </Grid>

            <Grid item xs={12} sm={6} md={6} style={workRowSecondItemStyle} >
              <WorkTypographyContainer>
                <Typography variant='p' sx={{ fontWeight: 'bold', color: '#fff ', lineHeight: 1.5 }}>National Finals: <br /> <span style={workTypoStyle}>Finalists to be decicded through weekly challenges</span></Typography>
              </WorkTypographyContainer>
            </Grid>
          </Grid>

          {/* fourth row */}
          <Grid container spacing={2} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: { lg: 'row-reverse', md: 'row-reverse', sm: 'row', xs: 'row' }, marginTop: 1 }} >
            <Grid item xs={12} sm={6} md={6}>
              <img src={card_globe} alt='asian' style={workImgStyle} />
            </Grid>

            <Grid item xs={12} sm={6} md={6} style={workRowSecondItemStyle} >
              <WorkTypographyContainer>
                <Typography variant='p' sx={{ fontWeight: 'bold', color: '#fff', lineHeight: 1.5 }}>Global Finals:<br /> <span style={workTypoStyle}>Top finalists to compete at ICode Global Hackathon</span></Typography>
              </WorkTypographyContainer>
            </Grid>
          </Grid>


        </WorkContainer>
      </Grid>
    </div>

    // <div style={{ backgroundColor: "#2A2F4F", color: "white" }}>
    //   <h1 className="fw-bold fs-1 text-center py-3">How it works?</h1>
    //   <div className="process pb-5">
    //     <Row className="mt-4">
    //       <Col sm={7} className="step">
    //         <img
    //           src="/Home/works/Register.png"
    //           width={320}
    //           height={166}
    //           alt="Register"
    //         />
    //       </Col>
    //       <Col sm={5}>
    //         <p className="fs-6 step">
    //           <span className="fw-bold">Register & self learning module:</span>
    //           <br /> Access to self learning modules after registrations
    //         </p>
    //       </Col>
    //     </Row>

    //     <Row className="mt-4">
    //     <Col sm={{order:2, span:7}} xs={{order:1, span:12}} className="step">
    //         <p className="fs-6">
    //           <span className="fw-bold">Weekly Challenges: </span>
    //           <br />
    //           Participate in weekly challenges to proceed to the next level
    //         </p>
    //       </Col>
    //       <Col sm={{order:2, span:7}} xs={{order:1, span:12}} className="step1">
    //         <img
    //           src="/Home/works/Challenges.png"
    //           width={320}
    //           height={166}
    //           alt="Challenges"
    //         />
    //       </Col>
    //     </Row>

    //     <Row className="mt-4">
    //       <Col sm={7} xs={12} className="step">
    //         <img
    //           src="/Home/works/Finalists.png"
    //           width={320}
    //           height={166}
    //           alt="Challenges"
    //         />
    //       </Col>
    //       <Col sm={5} xs={12} className="step">
    //         <p className="fs-6">
    //           <span className="fw-bold">National Finals: </span>
    //           <br />
    //           Finalists to be decicded through weekly challenges
    //         </p>
    //       </Col>
    //     </Row>

    //     <Row className="mt-4">
    //     <Col sm={{order:2, span:7}} xs={{order:1, span:12}} className="step">
    //         <p className="fs-6">
    //           <span className="fw-bold">Global Finals:</span>
    //           <br />
    //           Top finalists to compete at ICode Global Hackathon
    //         </p>
    //       </Col>
    //       <Col sm={{order:2, span:7}} xs={{order:1, span:12}} className="step1">
    //         <img
    //           src="/Home/works/Hackathon.png"
    //           width={320}
    //           height={166}
    //           alt="Challenges"
    //         />
    //       </Col>
    //     </Row>
    //   </div>
    // </div>
  );
};

export default Works;
