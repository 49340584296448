import React from "react";
import { Grid, Box, Typography } from '@mui/material';
import { AwardContainer, AwardImage, AwardRowBox, TimelineImage, EndImage } from "../../../assets/css/styles";
import '../../../assets/css/index.css'

import trophy_1 from '../../../assets/images/trophy_1.svg'
import reward from '../../../assets/images/reward.svg'
import diamond_award from '../../../assets/images/diamond_award.svg'
import trophy_8 from '../../../assets/images/trophy_8.svg'
import wave_new from '../../../assets/images/wave_new.png'
import rocket from '../../../assets/images/rocket.png'

const Awards = () => {
  const isMobile = window.innerWidth < 600;
  return (
    <div>
      {/* SCHOOL AWARDS & PARTICIPANT PARTNER AWARD */}
      <Grid sx={{ background: 'linear-gradient(to bottom, #1CB0FF, #0068DE)', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', paddingTop: 8, paddingBottom: 0, }} >
        <Box sx={{ width: { lg: "70%", xs: "70%" }, display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 10 }}>
          <Typography variant={isMobile ? 'h5' : 'h3'} sx={{ marginBottom: { lg: 5, xs: 0 }, fontWeight: 'bold', color: '#fff', textAlign: 'center', lineHeight: 1.5, }}>SCHOOL AWARDS & <br /> PARTICIPANT PARTNER AWARD</Typography>
        </Box>

        {/* first row */}
        <Box sx={{ width: { lg: "70%", xs: "100%" }, display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 1 }}>
          <Typography variant={isMobile ? 'h5' : 'h4'} sx={{ fontWeight: 'bold', color: '#fff', textAlign: 'center', lineHeight: 1.5, }}>Student Awards</Typography>
        </Box>

        <AwardContainer>
          <Grid container spacing={2} sx={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', flexDirection: 'row', }} >
            <Grid item xs={6} sm={6} md={6}  >
              <AwardRowBox>
                <AwardImage src={trophy_1} alt="award 1" />
                <Typography variant={isMobile ? 'p' : 'h5'} sx={{ marginTop: 3, fontWeight: 'bold', color: '#10284B', textAlign: 'center', }}>National<br />Winners</Typography>
              </AwardRowBox>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <AwardRowBox>
                <AwardImage src={reward} alt="award 1" />
                <Typography variant={isMobile ? 'p' : 'h5'} sx={{ marginTop: 3, fontWeight: 'bold', color: '#10284B', textAlign: 'center', }}>Global<br />Winners</Typography>
              </AwardRowBox>
            </Grid>
          </Grid>
        </AwardContainer>

        {/* second row */}
        <Box sx={{ width: "70%", display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
          <Typography variant={isMobile ? 'h5' : 'h4'} sx={{ fontWeight: 'bold', color: '#fff', textAlign: 'center', lineHeight: 1.5, }}>Student Awards</Typography>
        </Box>

        <AwardContainer>
          <Grid container spacing={2} sx={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', flexDirection: 'row', }} >
            <Grid item xs={6} sm={6} md={6}  >
              <AwardRowBox>
                <AwardImage src={trophy_1} alt="award 1" />
                <Typography variant={isMobile ? 'p' : 'h5'} sx={{ marginTop: 3, fontWeight: 'bold', color: '#10284B', textAlign: 'center', }}>Computational Leadership <br /> in Education: State</Typography>
              </AwardRowBox>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <AwardRowBox>
                <AwardImage src={reward} alt="award 1" />
                <Typography variant={isMobile ? 'p' : 'h5'} sx={{ marginTop: 3, fontWeight: 'bold', color: '#10284B', textAlign: 'center', }}>Computational Leadership <br /> in Education: National</Typography>
              </AwardRowBox>
            </Grid>
          </Grid>
        </AwardContainer>

        {/* third row */}

        <Box sx={{ width: "70%", display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10 }}>
          <Typography variant={isMobile ? 'h5' : 'h4'} sx={{ fontWeight: 'bold', color: '#fff', textAlign: 'center', lineHeight: 1.5, }}>Partner Awards</Typography>
        </Box>

        <AwardContainer>
          <Grid container spacing={2} sx={{ width: '100%', display: 'flex', justifyContent: 'space-evenly', alignItems: 'center', flexDirection: 'row', }} >
            <Grid item xs={6} sm={6} md={6}  >
              <AwardRowBox>
                <AwardImage src={diamond_award} alt="award 1" />
                <Typography variant={isMobile ? 'p' : 'h5'} sx={{ marginTop: 3, fontWeight: 'bold', color: '#10284B', textAlign: 'center', }}>Computational Education Excellence Award
                </Typography>
              </AwardRowBox>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <AwardRowBox>
                <AwardImage src={trophy_8} alt="award 1" />
                <Typography variant={isMobile ? 'p' : 'h5'} sx={{ marginTop: 3, fontWeight: 'bold', color: '#10284B', textAlign: 'center', }}><br/>Best Performing Partner</Typography>
              </AwardRowBox>
            </Grid>
          </Grid>
        </AwardContainer>


        {/* timeline */}
        <Typography variant='h4' sx={{ marginTop: 20, fontWeight: 'bold', color: "#fff" }}>Timelines</Typography>

        <Grid sx={{ width: '100%' }}>
          <TimelineImage src={rocket} alt="timeline" />
          <Grid sx={{ position: 'relative', width: '100%', marginTop: 10 }}>
            <EndImage src={wave_new} alt="wave" />
          </Grid>
        </Grid>
      </Grid>
    </div>
    // <div
    //   style={{
    //     background:
    //       "transparent radial-gradient(closest-side at 50% 50%, #1CB0FF 0%, #0068DE 100%)",
    //     textAlign: "center",
    //   }}
    // >
    //   <h1 className="fw-bold text-white text-center py-2 pt-3">
    //     SCHOOL AWARDS & <br />
    //     PARTICIPANT PARTNER AWARD
    //   </h1>
    //   <h2 className="fw-bold text-white text-center mt-5 py-3">
    //     Student Awards
    //   </h2>
    //   <div className="awardBox p-sm-5 p-2 p-0">
    //     <Row className="justify-content-center">
    //       <Col
    //         className="text-center p-0 m-0 py-3 award me-sm-4 me-2"
    //         sm={5}
    //         xs={5}
    //       >
    //         <div className="text-center">
    //           <img src="/Home/awards/Star.png" alt="star" className="star" />
    //         </div>
    //         <p className="mt-2 awardText">
    //           National
    //           <br /> Winners
    //         </p>
    //       </Col>
    //       <Col
    //         className="text-center p-0 m-0 py-3 award ms-sm-5 ms-1"
    //         sm={5}
    //         xs={5}
    //       >
    //         <div className="text-center">
    //           <img
    //             src="/Home/awards/Globe.png"
    //             alt="global"
    //             className="global"
    //           />
    //         </div>
    //         <p className="mt-2 awardText">
    //           GLobal <br />
    //           Winners
    //         </p>
    //       </Col>
    //     </Row>
    //   </div>

    //   {/* School Award */}
    //   <h2 className="fw-bold text-white text-center mt-5 py-3">
    //     School Awards
    //   </h2>
    //   <div className="awardBox p-sm-5 p-2 p-0">
    //     <Row className="justify-content-center">
    //       <Col
    //         className="text-center p-0 m-0 py-3 award me-sm-4 me-2"
    //         sm={5}
    //         xs={5}
    //       >
    //         <div className="text-center">
    //           <img src="/Home/awards/Star.png" alt="star" className="star" />
    //         </div>
    //         <p className="mt-2 awardText">
    //           Computational Leadership <br />
    //           in Education : State
    //         </p>
    //       </Col>
    //       <Col
    //         className="text-center p-0 m-0 py-3 award ms-sm-5 ms-1"
    //         sm={5}
    //         xs={5}
    //       >
    //         <div className="text-center">
    //           <img
    //             src="/Home/awards/Globe.png"
    //             alt="global"
    //             className="global"
    //           />
    //         </div>
    //         <p className="mt-2 awardText">
    //           Computational Leadership <br />
    //           in Education : National
    //         </p>
    //       </Col>
    //     </Row>
    //   </div>

    //   {/* School Award */}

    //   {/* Partner Award */}

    //   <h2 className="fw-bold text-white text-center mt-5 py-3">
    //     Partner Awards
    //   </h2>
    //   <div className="awardBox p-sm-5 p-2 p-0">
    //     <Row className="justify-content-center">
    //       <Col
    //         className="text-center p-0 m-0 py-4 award me-sm-4 me-2"
    //         sm={5}
    //         xs={5}
    //       >
    //         <div className="text-center">
    //           <img
    //             src="/Home/awards/DiamondAward.png"
    //             alt="diamondaward"
    //             className="diamondaward"
    //           />
    //         </div>
    //         <p className="mt-2 awardText">
    //           Computational Education Excellence Award
    //         </p>
    //       </Col>
    //       <Col
    //         className="text-center p-0 m-0 py-4 award ms-sm-5 ms-1"
    //         sm={5}
    //         xs={5}
    //       >
    //         <div className="text-center">
    //           <img
    //             src="/Home/awards/Diamond.png"
    //             alt="diamond"
    //             className="diamond"
    //           />
    //         </div>
    //         <p className="mt-2 awardText">Best Performing Partner</p>
    //       </Col>
    //     </Row>
    //   </div>
      
    //   {/* Partner Award */}

    //   <h2 className="text-white fw-bold mt-5">Timelines</h2>
    //   <div>
    //     <img
    //       src="/Home/awards/Timeline.svg"
    //       alt="timeline"
    //       style={{ width: "100%" }}
    //     />
    //   </div>
    //   <div>
    //     <img
    //       src="/Home/awards/bottomCurve.png"
    //       alt="curve"
    //       className="imageBottom"
    //       style={{ width: "100%" }}
    //     />
    //   </div>
    // </div>
  );
};

export default Awards;
