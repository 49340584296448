import { Button } from '@material-ui/core';
import { Modal } from '@mui/material';
import React from 'react';
import background from '../../assets/images/background.svg';
import highlight from '../../assets/images/ICODE-LOGO-2024.png';
import '../../assets/css/modal.css';
import {
  ThumbUpAltSharp,
  Facebook,
  Instagram,
  Twitter,
  LinkedIn,
  ThumbDownAltSharp,
} from '@mui/icons-material';
import { Link } from '@mui/material';
import { BASE_URl } from '../../api/api';
import { useLocation } from 'react-router-dom';

const ShowModal = ({ modalOpen, setErrorModal, titile, message, error, type }) => {
  const location = useLocation();
  const path = location.pathname;
  const myArray = path.split('/');

  // const onClicked = () => {
  //   setErrorModal(false);
  //   if (error === false) {
  //     if (myArray === 2) {
  //       window.location.href = 'https://icode.org/';
  //     } else {
  //       window.location.href = BASE_URl;
  //     }
  //   }
  // };

  const onClicked = (url) => {
    window.location.href = url;
  };

  return (
    <div>
      <Modal
        open={modalOpen}
        sx={{
          backgroundImage: `url(${background})`,
          backgroundColor: '#3eb7ff',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <div className="main-container">
          <img
            src={highlight}
            alt="img"
            height="130"
            width="auto"
            className="logo"
          />
          <div className="container-modal">
            {error ? <ThumbDownAltSharp /> : <ThumbUpAltSharp />}
            <div className="title">{titile}</div>
            <div className="message">{message}</div>

            {/* invite */}
            <div className="invite-head">Invite your Friends to join you!</div>
            <div className="icon-container">
              <Link
                href="https://www.facebook.com/icodecompetitions"
                target="_blank"
                rel="noopener"
              >
                <Facebook className="icon-link" />
              </Link>
              <Link
                href="https://twitter.com/IcodeGlobal"
                target="_blank"
                rel="noopener"
              >
                <Twitter className="icon-link" />
              </Link>
              <Link
                href="https://www.instagram.com/icode_global_hackathon/"
                target="_blank"
                rel="noopener"
              >
                <Instagram className="icon-link" />
              </Link>
              <Link
                href="https://www.linkedin.com/company/icodeglobal"
                target="_blank"
                rel="noopener"
              >
                <LinkedIn className="icon-link" />
              </Link>
            </div>
          </div>
          <div className="privacy">
            © {new Date().getFullYear()} ICode Foundation |{' '}
            <Link
              href="https://icode.org/privacy-policy/"
              target="_blank"
              rel="noopener"
            >
              Privacy Policy
            </Link>
            <Button className="btnClose bg-success" onClick={()=>onClicked(type == "school" ? 'https://icode.org/' : BASE_URl)}>
              {type == "school" ? "Ok" : "Start Practicing"}
            </Button>
          </div>
          
        </div>
      </Modal>
    </div>
  );
};

export default ShowModal;
