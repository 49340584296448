import React from 'react'
import Awards from './components/Home/Awards';
import { Banner } from './components/Home/Banner';
// import Footer from './components/Home/Footer';
import Ranking from './components/Home/Ranking';
import Works from './components/Home/Works';


const SchoolPage = () => {
    return (
        <div style={{maxWidth:"100vw", overflow:"hidden"}}>
            <Banner />
            {/* <Ranking />
            <Works /> */}
            {/* <Awards /> */}
            {/* <Footer /> */}
        </div>
    )
}

export default SchoolPage