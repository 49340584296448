let prod = false;
process.env.REACT_APP_ENVIRONMENT === 'true' ? prod = true : prod=false

export const gAnalyticsID = prod ? "G-ECC2B3PGWX" : "G-Z37L30BQ54"

export const BASE_URl = prod ? process.env.REACT_APP_PROD_BASE_URL : process.env.REACT_APP_DEV_BASE_URL;

export const postApiCall = async (apiEndpoints, sendData) => {
    
    return await fetch(BASE_URl + apiEndpoints, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/x-www-form-urlencoded"
      },
      body: sendData
    })
      .then(async response => {
        // console.log(response);
        if (response.status !== 200) {
          throw new Error("Not 200");
        } else {
          return await response.json();
        }
      })
      .then(data => {
        // console.log("DATA", data);
        return data;
      })
      .catch(err => {
        console.log(err);
        throw err;
      });
  }

  export const postApiCallStringyfy = async (apiEndpoints, sendData) => {
    
    return await fetch(BASE_URl + apiEndpoints, {
      method: "POST",
      headers: {
        "Accept": "application/json",
        "Content-Type": "application/x-www-form-urlencoded"
      },
      body: JSON.stringify(sendData)
    })
      .then(async response => {
        // console.log(response);
        if (response.status !== 200) {
          throw new Error("Not 200");
        } else {
          return await response.json();
        }
      })
      .then(data => {
        // console.log("DATA", data);
        return data;
      })
      .catch(err => {
        console.log(err);
        throw err;
      });
  }
