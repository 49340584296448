import React from "react";
import "./home.css";
import { Row, Col, Stack } from "react-bootstrap";
import { Typography } from "@material-ui/core";
import IndiaInternal from "../Signup/IndiaInternal";
import indiaLogo from '../../../assets/images/countries/indiaLogo.png';

export const BannerIndia = () => {

    return (
        <div className="banner">
            <Row>
                <Col className="text-center" sm={6}>
                    <img
                        src={indiaLogo}
                        alt="icode"
                        className="logoimg"
                    />
                </Col>
                <Col className="text-center d-flex justify-content-center align-items-center" sm={6} >
                    <Typography className="school-link-contain"
                    >
                        {/* <mark className="redirect-text">
                            This is a School Registration page, if you are a student then please <a href={onCick} className="school-link" onClick={() => onCick()
                                // pathname: `/${data.label}/student`,
                                // state: { data: data }
                            }>
                                click here
                            </a> to
                            register for ICode Global Hackathon.
                        </mark> */}

                    </Typography>
                </Col>
            </Row>
            <Row>
                <Col sm={6} className="mt-5 mb-5">
                    <p className="text-white text-center fw-bold fs-3">
                        Why ICode Global Hackathon?
                    </p>

                    <div className="d-none d-sm-block ">
                        <Stack direction="horizontal" className="justify-content-center col-contain">
                            <Col className="text-center" sm={5} xs={6}>
                                <img
                                    src="/Home/banner/Participant.svg"
                                    alt="Participant"
                                    width={154}
                                    height={154}
                                />
                                <p className="fs-6 text-white">
                                    8 Million+ Participants <br /> since 2017
                                </p>
                            </Col>
                            <Col className="text-center mb-4" sm={5} xs={6}>
                                <img
                                    src="/Home/banner/Nationalities.svg"
                                    alt="Nationalities"
                                    width={154}
                                    height={154}
                                />
                                <p className="fs-6 text-white">73+ Nationalities</p>
                            </Col>
                        </Stack>
                        <Stack direction="horizontal" className="justify-content-center col-contain">
                            <Col className="text-center" sm={5} xs={6}>
                                <img
                                    src="/Home/banner/Schools.svg"
                                    alt="Participant"
                                    width={154}
                                    height={154}
                                />
                                <p className="fs-6 text-white">3000+ Schools</p>
                            </Col>
                            <Col className="text-center" sm={5} xs={6}>
                                <img
                                    src="/Home/banner/GLobalRegions.svg"
                                    alt="Nationalities"
                                    width={154}
                                    height={154}
                                />
                                <p className="fs-6 text-white">9+ GLobal Regions</p>
                            </Col>
                        </Stack>
                    </div>

                    <Row className="d-sm-none px-2 mt-0">
                        <Col className="text-center" xs={3}>
                            <img
                                src="/Home/banner/Participant.svg"
                                alt="Participant"
                                width={60}
                                height={60}
                            />
                            <p className="text-white" style={{ fontSize: "10px" }}>
                                8 Million+ Participants <br /> since 2017
                            </p>
                        </Col>
                        <Col className="text-center mb-4" xs={3}>
                            <img
                                src="/Home/banner/Nationalities.svg"
                                alt="Nationalities"
                                width={60}
                                height={60}
                            />
                            <p className="text-white" style={{ fontSize: "10px" }}>73+ Nationalities</p>
                        </Col>
                        <Col className="text-center" xs={3}>
                            <img
                                src="/Home/banner/Schools.svg"
                                alt="Participant"
                                width={60}
                                height={60}
                            />
                            <p className="text-white" style={{ fontSize: "10px" }}>3000+ Schools</p>
                        </Col>
                        <Col className="text-center" xs={3}>
                            <img
                                src="/Home/banner/GLobalRegions.svg"
                                alt="Nationalities"
                                width={60}
                                height={60}
                            />
                            <p className="text-white" style={{ fontSize: "10px" }}>9+ GLobal Regions</p>
                        </Col>
                    </Row>
                </Col>

                <Col>
                    <IndiaInternal />
                </Col>
            </Row>
        </div>
    );
};
