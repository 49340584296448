import { useEffect, useState, useRef } from 'react';
import './signup.css';
import { Form, InputGroup, Stack } from 'react-bootstrap';
import Select from 'react-select';
import { BASE_URl, postApiCall, postApiCallStringyfy } from '../../../api/api';
import OtpInput from 'react-otp-input';
import { makeStyles, Button, Box } from '@material-ui/core';
import ShowModal from '../../../first/component/modal';
import Swal from 'sweetalert2';
import { useParams } from 'react-router-dom';
import { countryData } from '../../../Home';

const useStyles = makeStyles((theme) => ({
    getOtpButton: {
        width: '30%',
        fontSize: '15px',
        borderRadius: '50px',
        marginBottom: 20,
        textTransform: 'capitalize',
        backgroundColor: '#148108',
        '@media (max-width: 700px)': {
            width: '40%',
        },
    },
    submitOtpButton: {
        width: '100%',
        fontSize: '15px',
        borderRadius: '87px',
        textTransform: 'capitalize',
        backgroundColor: '#12B800',
        // color: otpBtnDisable ? "#fff" : "#000",
        padding: '10px',
        // marginRight: '10px',
        border: '1px solid white',
        '@media (max-width: 700px)': {
            marginTop: '1rem',
        },

        '&:visited': {
            Color: '#fff',
        },
    },
    submitButton: {
        width: '40%',
        fontSize: '16px',
        borderRadius: '87px',
        textTransform: 'capitalize',
        backgroundColor: '#FFAF10',
        padding: '10px',
        // marginRight: '10px',
        border: '1px solid white',
        fontWeight: 'bold',
    },
    otpBoxes: {
        width: '30px',
        marginLeft: '15px',
        '@media (max-width: 700px)': {
            // width: '15px',
            // marginLeft: '30px',
        },
    },
    resendButton: {
        backgroundColor: '#118f45',
        fontSize: 13,
        fontWeight: 'bold',
        color: '#fff',
        textTransform: 'capitalize',
        marginTop: 10,
        padding: 8,
        borderRadius: 15,
        '&:hover': {
            backgroundColor: '#FFAF10',
        },
    },
}));

const IndiaInternal = () => {
    const isMobile = window.innerWidth < 600;
    const classes = useStyles();

    const [stateList, setStateList] = useState([]);
    const [cityList, setCityList] = useState([]);
    const [verifiedBtnDisability, setVerifiedBtnDisability] = useState(false);
    const [otpBtnDisable, setOtpBtnDisable] = useState(false);
    const [getOtpBtnDisability, setGetOtpBtnDisability] = useState(false);

    const [otp, setOtp] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');

    const formRef = useRef();

    const { countryName } = useParams();
    const countryList = countryData


    const getData = async () => {
        const res = await postApiCall('ncl/getStates', 'country_id=1');
        setStateList(res[0]);
    }

    useEffect(() => {
        getData()
    }, [countryName, countryList])

    // timer
    const [countdown, setCountdown] = useState(180); // set initial countdown value to 3 minutes
    const [timerRunning, setTimerRunning] = useState(false); // track whether the timer is running or not

    useEffect(() => {

        let intervalId;
        if (timerRunning && countdown > 0) {
            intervalId = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000);
        }
        return () => clearInterval(intervalId);
    }, [timerRunning, countdown]);

    const minutes = Math.floor(countdown / 60);
    const seconds = countdown % 60;

    const [showTimer, setShowTimer] = useState(false);
    const [resendBtn, setResedBtn] = useState(false);

    useEffect(() => {
        if (countdown === 0) {
            setResedBtn(true);
            setCountdown(180);

            setTimerRunning(false);
        }
    }, [countdown]);


    const [errorModal, setErrorModal] = useState(false);
    const [title, setTitle] = useState('');
    const [messaage, setMessaage] = useState('');
    const [error, setError] = useState(false);

    // form submit
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = new FormData(formRef.current);
        const entries = Array.from(formData.entries());
        const formValues = entries.reduce(
            (prev, [name, value]) => ({
                ...prev,
                [name]: value,
            }),
            {}
        );

        const domainRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if (!(domainRegex.test(email))) {
            Swal.fire({
                title: 'Error',
                text: 'Please enter a valid email',
                icon: 'error'
            });
            return;
        }


        if (phone === '') {
            Swal.fire({
                title: 'Error',
                text: 'Please enter the phone number',
                icon: 'error'
            });
            return;
        }



        if (formValues.pin < 100000 || formValues.pin > 999999) {
            Swal.fire({
                title: 'Error',
                text: 'Pin code must be 6 digit number!',
                icon: 'error',
            });
            return;
        }

        if (phone === '') {
            Swal.fire({
                title: 'Error',
                text: 'Please enter the 10 digit phone number',
                icon: 'error',
            });
            return;
        }

        if (!otpBtnDisable) {
            Swal.fire({
                title: 'Error',
                text: 'Please verify your Phone Number First',
                icon: 'error'
            });
            return;
        }

        //   if (!emailFieldDisable) {
        //     Swal.fire({
        //       title: 'Error',
        //       text: 'Please verify your Email',
        //       icon: 'error'
        //     });
        //     return;
        //   }


        // console.log({
        //   ...formValues, country: '1',
        //   mobile: phone,
        //   email: email
        // });

        const res = await postApiCallStringyfy('ncl/addSite', {
            ...formValues,
            country: '1',
            mobile: phone,
            email: email
        });

        if (res[0].status === 'error') {
            Swal.fire({
                title: 'Error',
                text: res[0].response.message,
                icon: 'error',
            });
            return;
        }
        setTitle('Congratulations!');
        setMessaage(res[0].response.message);
        setError(false);
        setErrorModal(true);

        formRef.current.reset();
        setStateList([]);
        setCityList([]);
    };

    const handleSubmitCode = async (e) => {
        e.preventDefault();

        if (otp === '') {
            Swal.fire({
                title: 'Error',
                text: 'Provide OTP first!',
                icon: 'error',
            });
            return;
        }

        const sendData = {
            "otpType": "mobile",
            "otpTo": phone,
            "otp": otp
        }

        const res = await postApiCallStringyfy('ncl/verifyOtp', sendData);
        if (res[0].status === 'error') {
            Swal.fire({
                title: 'Error',
                text: res[0].response.message,
                icon: 'error',
            });

            return;
        }
        setResedBtn(false);
        setShowTimer(false);
        setVerifiedBtnDisability(true)
    };
    const handleGetOtp = async (e) => {
        e.preventDefault();

        setOtp('')
        if (phone === '') {
            Swal.fire({
                title: 'Error',
                text: 'Please enter the phone number',
                icon: 'error'
            });
            return;
        }

        if (phone < 1000000000 || phone > 9999999999) {
            Swal.fire({
                title: 'Error',
                text: 'Phone Number must be of 10 digit number',
                icon: 'error'
            });

            return;
        }

        const sendData = {
            "otpType": "mobile",
            "otpTo": phone 
        }
        // const sendData = {
        //     "otpType": data === '1' ? "mobile" : "email",
        //     "otpTo": data === '1' ? phone : email
        //   }


        const res = await postApiCallStringyfy('ncl/requestValidation', sendData);
        if (res[0].status === 'error') {
            Swal.fire({
                title: 'Error',
                text: res[0].response.message,
                icon: 'error',
            });
            return;
        }

        setShowTimer(true);
        setResedBtn(false);
        setTimerRunning(true);
        setGetOtpBtnDisability(true);

        setOtpBtnDisable(true)
        Swal.fire({
            title: 'Successful',
            text: res[0].response.message,
            icon: 'success',
        });
    };

    const [selectedCity, setSelectedCity] = useState(null);
    // getCities from state
    const onStateChange = async (val) => {

        setSelectedCity(null);

        await fetch(BASE_URl + "ncl/getNewCities", {
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "state_id": val.value,
                "country_id": '1',
            })
        })
            .then(async response => {
                if (response.status !== 200) {
                    throw new Error("Not 200");
                } else {
                    const res = await response.json();
                    setCityList(res)
                }
            })
            .catch(err => {
                console.log(err);
                throw err;
            });

    };

    // UNUSED getSchool from City
    // const onCityChange = async (val) => {
    //   const res = await postApiCall('ncl/getSchools', "city_id=" + val.value);
    //   const otherOption = { id: '0', state: '', city: '', name: 'Other' };
    //   // setSchoolList([...res, otherOption])
    // };

    // validation for name
    function handleKeyDown(event) {
        const regex = /^[a-zA-Z\s]*$/;
        if (!regex.test(event.key)) {
            event.preventDefault();
        }
    }

    return (
        <div>
            {errorModal && (
                <ShowModal
                    modalOpen={errorModal}
                    setErrorModal={setErrorModal}
                    titile={title}
                    message={messaage}
                    error={error}
                />
            )}
            <div className="signupbox mx-sm-5 mx-2">
                <h4 className="text-center py-4">School Registration Form</h4>
                <Form onSubmit={handleSubmit} ref={formRef}>
                    <Form.Group className="mb-3">
                        <Select
                            placeholder="Select State"
                            name="state"
                            onChange={(val) => onStateChange(val)}
                            options={stateList.map((item) => ({
                                label: item.name,
                                value: item.id,
                            }))}
                            required={true}
                            classNamePrefix="filter-select"
                            className="filterButton"
                            autoComplete="new-password"
                        />

                        <Form.Control.Feedback type="invalid">
                            Please select Your State.
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3 ">
                        <Select
                            value={selectedCity}
                            placeholder="Select City"
                            name="city"
                            onChange={(val) => {
                                setSelectedCity(val)
                            }}
                            options={cityList.map((item) => ({
                                label: item.name,
                                value: item.id,
                            }))}
                            required={true}
                            classNamePrefix="filter-select"
                            className="filterButton"
                            autoComplete="new-password"
                        />

                        <Form.Control.Feedback type="invalid">
                            Please select Your State.
                        </Form.Control.Feedback>
                    </Form.Group>

                    {/* <Form.Group className="mb-3">
          <Select
            placeholder="Your School"
            name="school"
            options={schoolList.map((item) => ({
              label: item.name,
              value: item.id,
            }))}
            required={true}
            onChange={(val) => {
              val.label === 'Other' ? setIsOther(true) : setIsOther(false)
            }}
            classNamePrefix="filter-select"
            className="filterButton"
          />
          <Form.Control.Feedback type="invalid">
            Please select Your School.
          </Form.Control.Feedback>
        </Form.Group> */}
                    {/* 
        {isOther && <Form.Group className="mb-3" controlId="formName" id="otherSchool">
          <Form.Control
            type="text"
            placeholder="School Name"
            //   value={input?.other_school ?? ""}
            className=""
            classNamePrefix="form-control"
            required={false}
            onChange={(e) => setOtherSchool(e.target.value)}
            onPaste={(event) => {
              const pastedData = event.clipboardData.getData("text/plain");
              if (/[^a-zA-Z0-9\s]/ims.test(pastedData)) {
                event.preventDefault();
              }
            }}
          />
          <Form.Control.Feedback type="invalid">
            Please enter School Name.
          </Form.Control.Feedback>
        </Form.Group>} */}
                    {/* )} */}

                    <Form.Group className="mb-3" controlId="formName">
                        <Form.Control
                            type="text"
                            name="site_name"
                            placeholder="School Name"
                            className="font-normal"
                            required={true}
                            autoComplete='new-password'
                        />
                        <Form.Control.Feedback type="invalid">
                            Please enter School Name.
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formName">
                        <Form.Control
                            type="text"
                            name="address"
                            placeholder="Address"
                            className="font-normal"
                            required={true}
                            autoComplete='new-password'
                        />
                        <Form.Control.Feedback type="invalid">
                            Please enter School Address.
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group
                        className="mb-3"
                        controlId="formName"
                        id="otherSchool"
                        style={{ width: '100%' }}
                    >
                        <Form.Control
                            type="number"
                            name="pin"
                            placeholder="Pin Code"
                            // value={formData.pin}
                            classNamePrefix="form-control"
                            required={true}
                            onInput={(e) => {
                                e.target.value = Math.max(0, parseInt(e.target.value))
                                    .toString()
                                    .slice(0, 6)
                            }}
                            autoComplete="new-password"
                        />
                        <Form.Control.Feedback type="invalid">
                            Please enter Pin Code.
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="formName">
                        <Form.Control
                            type="text"
                            name="site_owner_name"
                            placeholder="Contact Person Name"
                            className="font-normal"
                            required={true}
                            onKeyDown={handleKeyDown}
                            autoComplete="new-password"
                        />
                        <Form.Control.Feedback type="invalid">
                            Please enter Contact Person Name.
                        </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="flex-1 mb-3">
                        <Form.Select
                            className="font-normal"
                            name="designation"
                            autoComplete="new-password"
                            required={true}
                        >
                            <option value="">Contact Person Designation</option>
                            <option value="Principal">Principal</option>
                            <option value="Director">Director</option>
                            <option value="Coordinator">Coordinator</option>
                            <option value="ICT Teacher">ICT Teacher</option>
                            <option value="Others">Others</option>
                        </Form.Select>
                        <Form.Control.Feedback type="invalid">
                            Please select Your Contact Person Designation.
                        </Form.Control.Feedback>
                    </Form.Group>

                    {/* email */}

                    <Box
                        className='form-align-box1' 
                    >
                        {/* email */}
                        <Stack direction="horizontal" gap={2}>
                            <Form.Group
                                className="mb-3"
                                controlId="formName"
                                id="otherSchool"
                                style={{ width: '100%' }}

                            >
                                <Form.Control
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                    className="font-normal"
                                    onChange={(event) => setEmail(event.target.value)}
                                    autoComplete="new-password"
                                />
                            </Form.Group>

                        </Stack>

                        {/* phone */}
                        <Stack direction="horizontal" className="w-100" gap={1}>

                            {/* <Form.Group controlId="formName" id="otherSchool" style={{ width: '100%' }}> */}
                            {/* <InputGroup>
                  <InputGroup.Text>+91</InputGroup.Text>
                  <Form.Control
                    type="number"
                    placeholder={countryDetails.code}
                    disabled={otpBtnDisable}
                    onChange={(event) => setPhone(event.target.value)}
                    classNamePrefix="form-control"
                    required={true}
                    onInput={(e) => {
                      e.target.value = Math.max(0, parseInt(e.target.value))
                        .toString()
                        .slice(0, 10);
                    }}
                    autoComplete="new-password"
                  />
                </InputGroup>
                <Form.Control.Feedback type="invalid">
                  Please enter Phone number.
                </Form.Control.Feedback>
              </Form.Group> */}


                            <Form.Group
                                className="mb-3 form-test"
                                controlId="formName"
                                id="otherSchool"
                                style={{ width: '100%' }}
                            >
                                {/* <div className='testing'> */}
                                <InputGroup>
                                    <InputGroup.Text className='country-code'>+91</InputGroup.Text>
                                    <Form.Control
                                        type="number"
                                        // placeholder={countryDetails.code}
                                        onChange={(event) => setPhone(event.target.value)}
                                        classNamePrefix="form-control"
                                        required={true}
                                        onInput={(e) => {
                                                e.target.value = Math.max(0, parseInt(e.target.value))
                                                    .toString()
                                                    .slice(0, 10)
                                        }}
                                        autoComplete="new-password"
                                        className='input-style'
                                    />
                                </InputGroup>
                                <Form.Control.Feedback type="invalid">
                                    Please enter Phone number.
                                </Form.Control.Feedback>
                                {/* </div> */}

                            </Form.Group>
                            <Button
                            disabled={getOtpBtnDisability}
                                variant="contained"
                                color="primary"
                                onClick={handleGetOtp}
                                className={classes.getOtpButton}
                            // className="rounded-pill mb-4 py-0 fw-bold"
                            >
                                Get Otp
                            </Button>

                            {/* <Button disabled={otpBtnDisable} onClick={(e) => handleGetOtp(e)} variant="warning" className="rounded-pill mb-4 py-0 fw-bold">
            Get Otp
          </Button> */}
                        </Stack>

                    </Box>

                    <Stack
                        direction={isMobile ? 'Vertical' : 'horizontal'}
                        className="submitOTP-stack"
                        gap={2}
                    >
                        <OtpInput
                            value={otp}
                            onChange={(val) => setOtp(val)}
                            numInputs={6}
                            separator={<span> </span>}
                            className={classes.otpBoxes}
                            inputStyle="test"
                            isInputNum={true}
                        />
                        <div>
                            <Button
                                variant="contained"
                                color="primary"
                                disabled={verifiedBtnDisability}
                                onClick={handleSubmitCode}
                                className={classes.submitOtpButton}
                            >
                                 {verifiedBtnDisability ? "Verified" : "Verify OTP"}
                            </Button>

                            {/* <Button disabled={otpBtnDisable} onClick={handleSubmitCode} variant="warning" className="rounded-pill mb-2 py-sm-2 text-white border border-2 border-light submitbtn" >
              {otpBtnDisable ? "Verified" : "Submit Code"}
            </Button> */}
                        </div>
                    </Stack>

                    {showTimer && (
                        <div className="d-flex justify-content-center">
                            {!resendBtn ? (
                                <p className="text-center  mt-1">
                                    {`${minutes.toString().padStart(2, '0')}:${seconds
                                        .toString()
                                        .padStart(2, '0')}`}
                                </p>
                            ) : (
                                <Button className={classes.resendButton} onClick={handleGetOtp}>
                                    Resend
                                </Button>
                            )}
                        </div>
                    )}

                    <div className="submit-button">
                        <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            className={classes.submitButton}
                        >
                            Submit
                        </Button>
                    </div>

                    <div className='consent-input'>
                        By registering here, I agree to ICode&nbsp;
                        <a href="https://icode.org/terms-of-use/" target='_blank' rel='noreferrer noopener'>
                            Terms & Conditions
                        </a> and&nbsp;
                        <a href="https://icode.org/privacy-policy/" target='_blank' rel='noreferrer noopener'>
                            Privacy Policy
                        </a>
                    </div>
                </Form>
            </div>
        </div>
    );
};

export default IndiaInternal;
