import React from 'react'
import Awards from './components/Home/Awards';
// import Footer from './components/Home/Footer';
import Ranking from './components/Home/Ranking';
import Works from './components/Home/Works';
import { BannerIndia } from './components/Home/BannerIndia';

const IndiaIndex = () => {
    return (
        <div>
            <BannerIndia />
            <Ranking />
            <Works />
            <Awards />
        </div>
    )
}

export default IndiaIndex