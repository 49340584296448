import { Grid, Box, Typography } from '@mui/material';
import './assets/css/index.css';
import Group_25185 from './assets/images/Group_25185.svg';
import kid_1 from './assets/images/kid_1.png';
import kid_2 from './assets/images/kid_2.png';
import coding from './assets/images/coding.png';
import develops from './assets/images/develops.png';
import back from './assets/images/home/back.svg';
import backSide from './assets/images/home/backSide.svg';
import homePath2 from './assets/images/home/homePath2.svg';
import home_children from './assets/images/home/home_children.jpg';
import first from './assets/images/home/first.svg';
import second from './assets/images/home/second.svg';
import third from './assets/images/home/third.svg';
import {
  RankingCardContainer,
  RankingRoundImage,
  HackathonRoundImage,
  HackathonContainer,
  HomeRoundImage,
  HomeButtonRoundImage,
  FirstHomeButtonRoundImage,
} from './assets/css/styles';
import { Button, makeStyles } from '@material-ui/core';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import Select from 'react-select';
// import Footer from './second/components/Home/Footer';
import Awards from './second/components/Home/Awards';
import Works from './second/components/Home/Works';
import Ghana from './assets/images/countries/Ghana.png';
import NewZealand from './assets/images/countries/NewZeland.png';
import singapore from './assets/images/countries/singapore.png';
import canada from './assets/images/countries/canada.png';
import australia from './assets/images/countries/australia.png';
import indiaLogo from './assets/images/countries/indiaLogo.png';
import UAE from './assets/images/countries/UAE.png';
import Indonesia from './assets/images/countries/Indonesia.png';
import Japan from './assets/images/countries/Japan.png';
import Netherlands from './assets/images/countries/Netherlands.png';
import Bangladesh from './assets/images/countries/Bangladesh.png';
import UK from './assets/images/countries/UK.png';
import Seychelles from './assets/images/countries/Seychelles.png';
import usa from './assets/images/countries/usa.png';
import ksa from './assets/images/countries/ksa.png';
import malaysia from './assets/images/countries/malaysia.png';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: 250,
    marginLeft: '-16px',
    borderRadius: '0px 30px 30px 0',
    backgroundColor: '#fff',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none',
      },
    },
    '@media (max-width: 750px)': {
      marginLeft: '-14px',
    },
  },
  formControlDiv: {
    backgroundColor: '#fff',
    borderRadius: 30,
    margin: theme.spacing(1),
    width: 300,
    height: 57,
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none',
      },
    },
    '@media (max-width: 750px)': {
      // width: '100%',
    },
  },
  registerButton: {
    width: '50%',
    fontSize: '15px',
    borderRadius: '87px',
    backgroundColor: '#fff',
    paddingLeft: '40px',
    paddingRight: '3px',
    paddingTop: '10px',
    paddingBottom: '10px',
    color: '#000',
    fontWeight: 'bold',
    marginRight: '30px',
    border: '1px solid white',

    '@media (max-width: 1240px)': {
      width: '70%',
      fontSize: '12px',
      paddingLeft: '25px',
      marginRight: '10px',
    },

    '@media (max-width: 1000px)': {
      width: '80%',
      fontSize: '12px',
      marginRight: '10px',
      paddingLeft: '30px',
    },

    '@media (max-width: 900px)': {
      width: '100%',
      fontSize: '9px',
      marginRight: '10px',
      paddingLeft: '40px',
    },

    '@media (max-width: 750px)': {
      width: '100%',
      fontSize: '9px',
      marginRight: '10px',
      paddingLeft: '25px',
    },
    '&:hover': {
      backgroundColor: '#fedf30',
    },
  },
  formLabel: {
    fontWeight: 'bold',
  },
}));

export const countryData = [
  {
    id: '1',
    img: indiaLogo,
    value: 'India',
    slug: 'india',
    code: '+91'
  },
  {
    id: '2',
    img: singapore,
    value: 'Singapore',
    slug: 'singapore',
    code: '+65'
  },
  {
    id: '3',
    img: NewZealand,
    value: 'New Zealand',
    slug: 'NZ',
    code: '+64'
  },

  {
    id: '4',
    img: australia,
    value: 'Australia',
    slug: 'australia',
    code: '+61'
  },
  {
    id: '5',
    img: canada,
    value: 'Canada',
    slug: 'canada',
    code: '+1'
  },
  {
    id: '6',
    img: UAE,
    value: 'UAE',
    slug: 'uae',
    code: '+971'
  },
  {
    id: '7',
    img: Indonesia,
    value: 'Indonesia',
    slug: 'indonesia',
    code: '+62'
  },
  {
    id: '8',
    img: Ghana,
    value: 'Ghana',
    slug: 'ghana',
    code: '+233'
  },
  {
    id: '9',
    img: Japan,
    value: 'Japan',
    slug: 'japan',
    code: '+81'
  },
  {
    id: '10',
    img: Netherlands,
    value: 'Netherlands',
    slug: 'netherlands',
    code: '+31'
  },
  {
    id: '11',
    img: Bangladesh,
    value: 'Bangladesh',
    slug: 'bangladesh',
    code: '+880'
  },
  {
    id: '12',
    img: UK,
    value: 'UK',
    slug: 'uk',
    code: '+44'
  },
  {
    id: '13',
    img: Seychelles,
    value: 'Seychelles',
    slug: 'seychelles',
    code: '+248'
  },
  {
    id: '14',
    img: usa,
    value: 'USA',
    slug: 'usa',
    code: '+1'
  },
  {
    id: '15',
    img: malaysia,
    value: 'Malaysia',
    slug: 'malaysia',
    code: '+60'
  },
  {
    id: '16',
    img: ksa,
    value: 'Saudi Arabia',
    slug: 'ksa',
    code: '+966'
  },
];

export const countryDataIndia = [
  {
    id: '1',
    img: indiaLogo,
    value: 'India',
  },
];

function Home() {
  const navigate = useNavigate();

  const classes = useStyles(); // Set your own breakpoint value here

  const [country, setCountry] = useState('');
  // const [countryValue, setCountryValue] = useState([]);
  const [isCountrySelected, setIsCountrySelected] = useState(false);

  const onSchoolClick = (e) => {
    if (country === '') {
      setIsCountrySelected(true);
      return;
    }
    if (country === 'Saudi Arabia')
      navigate(`/ksa`);
    else if (country === 'New Zealand')
      navigate(`/nz`);
    else
      navigate(`/${country}`);
  };

  const onStudentCLick = (e) => {
    if (country === '') {
      setIsCountrySelected(true);
      return;
    }
    if (country === 'Saudi Arabia')
      navigate(`/ksa/student`);
    else if (country === 'New Zealand')
      navigate(`/nz/student`);
    else
      navigate(`/${country}/student`);
  };
  return (
    <div>
      <Grid
        item
        container
        spacing={2}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: { lg: 'row', xs: 'row' },
          marginTop:0
        }}
      >
        <Grid
          item
          className="home-background"
          xs={12}
          sm={12}
          md={1}
          sx={{
            backgroundImage: `url(${backSide})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            minHeight: {lg: "100vh"}
          }}
        ></Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={11}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: { lg: 'row', xs: 'column' },
            // zIndex: 1000,
            backgroundImage: `url(${back})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={7}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              padding: { lg: 10, xs: 3 },
            }}
          >
            <Box
              sx={{
                paddingBottom: 8,
              }}
            >
              <Box
                sx={{
                  position: 'relative',
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
                className={[classes.formControlDiv, 'from-country']}
              >
                <FirstHomeButtonRoundImage src={first} />

                <Form.Group className="country-select">
                  <Select
                    placeholder="Select Country"
                    // name='state'
                    // value={country}
                    onChange={(val) => {
                      // setCountryValue(val);
                      setCountry(val.label);
                    }}
                    options={countryData.map((item) => ({
                      label: item.value,
                      value: item.id,
                      img: item.img,
                    }))}
                    required={false}
                    classNamePrefix="filter-select"
                    className="filterButton"
                  />

                  <Form.Control.Feedback type="invalid">
                    Please select Your State.
                  </Form.Control.Feedback>
                </Form.Group>
              </Box>
            </Box>

            <Box
              sx={{
                paddingBottom: 8,
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  marginBottom: 2,
                  fontWeight: 'bold',
                  color: 'white',
                }}
              >
                ICode Global Hackathon
              </Typography>

              <Typography
                variant="p"
                sx={{
                  color: '#fff',
                }}
              >
                {/* your entry point to the ICode Global Hackathon 2023, the world's
                largest coding competition for kids! */}
                Welcome to the ICode Global Hackathon 2024, the most thrilling coding competition just for kids! 
                <br />
                <br />
                Get ready to join forces with young geniuses from every corner of the world, all vying for the ultimate title in the Global Finals. It's your chance to shine and show off your coding prowess on a worldwide stage! Join us for an epic coding adventure like no other, where fun meets challenge and creativity knows no bounds. Don't miss out on this incredible opportunity to be part of something truly extraordinary!
              </Typography>
            </Box>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
                width: '100%',
              }}
            >
              <Button
                variant="contained"
                color="primary"
                className={classes.registerButton}
                onClick={(e) => onSchoolClick()}
                startIcon={<HomeButtonRoundImage src={second} />}
              >
                School Registration
              </Button>

              <Button
                variant="contained"
                color="primary"
                className={classes.registerButton}
                onClick={(e) => onStudentCLick()}
                startIcon={<HomeButtonRoundImage src={third} />}
              >
                Student Registration
              </Button>
            </Box>
            {isCountrySelected && (
              <Typography
                variant="h4"
                sx={{
                  color: '#fedf30',
                  fontSize: 14,
                  fontWeight: 'bold',
                  textAlign: 'center',
                  padding: 3,
                }}
              >
                Please select the country first!
              </Typography>
            )}
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={5}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              // height: "100%",
              // width: "100%",
            }}
          >
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                width: { lg: '329px', sm: '275px', xs: '275px' },
                height: { lg: '270px', sm: '227px', xs: '227px' },
                backgroundImage: `url(${homePath2})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                marginBottom: { lg: 0, md: 0, sm: 0, xs: 4 },
              }}
            >
              <HomeRoundImage src={home_children} alt="Children picture" />
            </Box>
          </Grid>
        </Grid>
      </Grid>

      {/* Ranking */}
      {/* <Grid
        item
        container
        spacing={2}
        sx={{
          backgroundColor: '#FEDF30',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          paddingTop: 7,
          paddingBottom: 7,
          marginTop: { 0 },
          // zIndex: '1 !important'
        }}
      > */}
        {/* <Typography variant="h4" sx={{ marginBottom: 3, fontWeight: 'bold' }}>
          Rankings
        </Typography>
        <div className="ranking-row">
          <RankingCardContainer>
            <RankingRoundImage src={Group_25185} alt="Profile picture" />
            <div>
              <Typography
                sx={{ fontWeight: 'bold', fontSize: { lg: 16, xs: 14 } }}
              >
                Global Ranking
              </Typography>
            </div>
          </RankingCardContainer>

          <RankingCardContainer sx={{ marginLeft: 10 }}>
            <RankingRoundImage src={Group_25185} alt="Profile picture" />
            <div>
              <Typography
                sx={{ fontWeight: 'bold', fontSize: { lg: 16, xs: 14 } }}
              >
                National Ranking
              </Typography>
            </div>
          </RankingCardContainer>
        </div> */}
      {/* </Grid> */}

      {/* Global Hackathon */}
      {/* <Grid
        item
        sx={{
          backgroundColor: '#fff',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          paddingTop: 8,
          paddingBottom: { lg: 8, xs: 0 },
        }}
      >
        <Typography
          variant="h4"
          sx={{ marginBottom: 3, fontWeight: 'bold', textAlign: 'center' }}
        >
          What’s ICode Global Hackathon?
        </Typography>

      
        <Grid
          item
          container
          spacing={2}
          sx={{
            backgroundColor: '#fff',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            paddingTop: { lg: 8, xs: 0 },
            paddingBottom: { lg: 0, xs: 4 },
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <HackathonContainer>
              <HackathonRoundImage src={kid_1} alt="kid_1" />
              <Typography
                variant="p"
                sx={{
                  marginBottom: { lg: 3, xs: 0 },
                  fontWeight: 'bold',
                  textAlign: { lg: 'center', xs: 'left' },
                  color: { lg: '#10284B', xs: '#fff' },
                  fontSize: { lg: 16, xs: 14 },
                  marginLeft: { lg: 0, xs: 8 },
                  paddingTop: { lg: 0, xs: 2 },
                  paddingBottom: { lg: 0, xs: 2 },
                }}
              >
                World’s Largest Coding Competition which enables K12 students to
                benchmark their coding skills with their peers globally
              </Typography>
            </HackathonContainer>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <HackathonContainer>
              <HackathonRoundImage src={coding} alt="kid_1" />
              <Typography
                variant="p"
                sx={{
                  marginBottom: { lg: 3, xs: 0 },
                  fontWeight: 'bold',
                  textAlign: { lg: 'center', xs: 'left' },
                  color: { lg: '#10284B', xs: '#fff' },
                  fontSize: { lg: 16, xs: 14 },
                  marginLeft: { lg: 0, xs: 8 },
                  paddingTop: { lg: 0, xs: 2 },
                  paddingBottom: { lg: 0, xs: 2 },
                }}
              >
                Only platform which provides an opportunity to the kids to
                compete at the global levels
              </Typography>
            </HackathonContainer>
          </Grid>
        </Grid>

      
        <Grid
          item
          container
          spacing={2}
          sx={{
            backgroundColor: '#fff',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
            paddingTop: { lg: 8, xs: 0 },
          }}
        >
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <HackathonContainer>
              <HackathonRoundImage src={kid_2} alt="kid_2" />
              <Typography
                variant="p"
                sx={{
                  marginBottom: { lg: 3, xs: 0 },
                  fontWeight: 'bold',
                  textAlign: { lg: 'center', xs: 'left' },
                  color: { lg: '#10284B', xs: '#fff' },
                  fontSize: { lg: 16, xs: 14 },
                  marginLeft: { lg: 0, xs: 8 },
                  paddingTop: { lg: 0, xs: 2 },
                  paddingBottom: { lg: 0, xs: 2 },
                }}
              >
                Global participation of 2.15 million student from 73+
                Nationalities
              </Typography>
            </HackathonContainer>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <HackathonContainer>
              <HackathonRoundImage src={develops} alt="kid_1" />
              <Typography
                variant="p"
                sx={{
                  marginBottom: { lg: 3, xs: 0 },
                  fontWeight: 'bold',
                  textAlign: { lg: 'center', xs: 'left' },
                  color: { lg: '#10284B', xs: '#fff' },
                  fontSize: { lg: 16, xs: 14 },
                  marginLeft: { lg: 0, xs: 8 },
                  paddingTop: { lg: 0, xs: 2 },
                  paddingBottom: { lg: 0, xs: 2 },
                }}
              >
                Develops Computational thinking & logical reasoning skills
              </Typography>
            </HackathonContainer>
          </Grid>
        </Grid>
      </Grid> */}

      {/* How it works */}
      {/* <Works /> */}

      {/* SCHOOL AWARDS & PARTICIPANT PARTNER AWARD */}
      {/* <Awards /> */}

      {/* <Footer /> */}
    </div>
  );
}

export default Home;
