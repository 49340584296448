import React from "react";
import { Row, Col, Stack } from "react-bootstrap";

const Ranking = () => {
  return (
    <>
      <div className="bg-warning py-4">
        <h1 className="fw-bold fs-1 text-center pb-2">Rankings</h1>
        <Row className="justify-content-center">
          <Col
            className="bg-white rounded-pill p-sm-2 text-center me-sm-4 me-0 mb-sm-0 mb-3 py-2"
            sm={2}
            xs={10}
          >
            <p className="fs-5 fw-bolder m-0">
              <span className="me-2">
                <img
                  src="/Home/ranking/Global.svg"
                  width={49}
                  height={44}
                  alt="global"
                />
              </span>
              Global Ranking
            </p>
          </Col>
          <Col
            className="bg-white rounded-pill p-sm-2 text-center py-2"
            sm={2}
            xs={10}
          >
            <p className="fs-5 fw-bolder m-0">
              <span className="me-2">
                <img
                  src="/Home/ranking/National.svg"
                  width={49}
                  height={44}
                  alt="national"
                />
              </span>
              National Ranking
            </p>
          </Col>
        </Row>
      </div>
      <div className="py-4">
        <h1 className="fw-bold fs-1 text-center pb-2">
          What’s ICode Global Hackathon?
        </h1>
        <Stack direction="horizontal" className="justify-content-center mt-5">
          <Col className="text-center" sm={5} xs={6}>
            <img
              src="/Home/ranking/Competition.png"
              alt="Competition"
              width={162}
              height={162}
            />
            <p className="caption">
              World’s Largest Coding Competition which enables K12 students to
              benchmark their coding skills with their peers globally
            </p>
          </Col>
          <Col className="text-center" sm={5} xs={6}>
            <img
              src="/Home/ranking/Platform.png"
              alt="Platform"
              width={162}
              height={162}
            />
            <p className="caption">
              Only platform which provides an opportunity to the kids to compete
              at the global levels
            </p>
          </Col>
        </Stack>
        <Stack direction="horizontal" className="justify-content-center pt-5">
          <Col className="text-center" sm={5} xs={6}>
            <img
              src="/Home/ranking/Student.png"
              alt="Student"
              width={162}
              height={162}
            />
            <p className="caption">
              Global participation of 2.15 million student from 73+
              Nationalities
            </p>
          </Col>
          <Col className="text-center" sm={5} xs={6}>
            <img
              src="/Home/ranking/Develops.png"
              alt="Develops"
              width={162}
              height={162}
            />
            <p className="caption">
              Develops Computational thinking & logical reasoning skills
            </p>
          </Col>
        </Stack>
      </div>

      
    </>
  );
};

export default Ranking;
