import { Box } from '@mui/material';
import { styled } from '@mui/system';

// Home
export const HomeRoundImage = styled('img')({
  borderRadius: '50%',
  width: '300px',
  height: '300px',
  padding: "20px",
  '@media only screen and (max-width: 700px)': {
    width: '250px',
    height: '250px',
    marginBottom: '13px'
  }
});

export const FirstHomeButtonRoundImage = styled('img')({
  borderRadius: '50%',
  width: '60px',
  height: '60px',
  zIndex: 1,
  '@media only screen and (max-width: 700px)': {
    width: '70px',
    height: '70px',
    zIndex: 1,
  }
});

export const HomeButtonRoundImage = styled('img')({
  borderRadius: '50%',
  width: '60px',
  height: '60px',
  position: 'absolute',
  left: -7,
  bottom: -8,
  '@media only screen and (max-width: 700px)': {
    width: '40px',
    height: '40px',
    left: -7,
    bottom: -2,
  }
});
// end Home

export const CardContainer = styled(Box)({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  padding: '14px',
  borderRadius: '15px',
  maxWidth: '450px',
  marginTop: '1rem',
  '@media only screen and (max-width: 700px)': {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    padding: '5px',
    borderRadius: '15px',
    maxWidth: '100%',
    marginTop: '1rem',
    marginLeft: "1.5rem",
    marginRight: "0.7rem"
  }
});


export const RoundImage = styled('img')({
  borderRadius: '50%',
  width: '110px',
  height: '110px',
  position: 'absolute',
  left: -40,
  '@media only screen and (max-width: 1200px)': {
    width: '80px',
    height: '80px',
    position: 'absolute',
    left: -15,
  }
});

export const RankingCardContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  padding: '8px 20px',
  borderRadius: '50px',
  maxWidth: '700px',
  margin: '0 auto',
  backgroundColor: "#fff",
  '@media only screen and (max-width: 700px)': {
    padding: '4px 10px',
    borderRadius: '50px',
    maxWidth: '100%',
    margin: '0 5px',
    backgroundColor: "#fff",
  }
});


// Define a custom styled component for the round image
export const RankingRoundImage = styled('img')({
  borderRadius: '50%',
  width: '40px',
  height: '40px',
  '@media only screen and (max-width: 700px)': {
    width: '25px',
    height: '25px',
  }
});

export const HackathonRoundImage = styled('img')({
  borderRadius: '50%',
  width: '170px',
  height: '170px',
  marginTop: '1rem',
  '@media only screen and (max-width: 700px)': {
    position: "absolute",
    left: -20,
    width: '120px',
    height: '120px',
    marginTop: '0rem',
  }
});

export const HackathonContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '16px',
  padding: '14px',
  borderRadius: '15px',
  maxWidth: '350px',
  margin: '0 auto',
  marginTop: '1rem',
  '@media only screen and (max-width: 700px)': {
    marginTop: "2rem",
    marginLeft: "4rem",
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column-reverse',
    maxWidth: '100%',
    padding: '0px',
    backgroundColor: "#2379EB",
    borderRadius: '0px',
    height: "100px"
  }
});

export const WorkContainer = styled(Box)({
  width: '50%',
  margin: '0 auto',
  '@media only screen and (max-width: 700px)': {
    width: '85%',
    margin: '0 0',

  }
});

export const WorkTypographyContainer = styled(Box)({
  maxWidth: '280px',
  '@media only screen and (max-width: 700px)': {
    maxWidth: '90%',
    textAlign: 'center'
  }
});

export const workImgStyle = {
  width: '78%',
};



export const workTypoStyle = {
  fontWeight: 'normal'
};

export const workRowSecondItemStyle = {
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  '@media only screen and (max-width: 700px)': {
    // display: 'flex',
    // justifyContent: 'flex-start',
    // alignItems: 'flex-start',
  }
};

export const AwardContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '16px',
  padding: '30px 50px',
  border: "1px solid #707070",
  borderRadius: '20px',
  width: '46%',
  backgroundColor: "#ffffff42",
  '@media only screen and (max-width: 700px)': {
    padding: '11px 10px',
    border: "1px solid #707070",
    borderRadius: '15px',
    width: '85%',
  justifyContent: 'center',
  }
});

export const AwardRowBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',
  padding: '18px 4px',
  borderRadius: '15px',
  width: '100%',
  margin: '0 auto',
  backgroundColor: "#ffffff",
  '@media only screen and (max-width: 700px)': {
    padding: '5px 8px',
    border: "1px solid #707070",
    borderRadius: '15px',
    width: '100%',
  }
});

export const AwardImage = styled('img')({
  width: "130px",
  height: "200px",
  padding: "9px 8px",
  '@media only screen and (max-width: 700px)': {
    width: "70px",
    height: "110px",
    padding: "0px 0px",
  }
});

export const TimelineImage = styled('img')({
  width: "100%"
});

export const EndImage = styled('img')({
  width: "100%",
  position: 'absolute',
  bottom: 0,
  marginBottom: -30
});

const mediaQuery = window.matchMedia('(maxWidth: 700px)');
if (mediaQuery.matches) {
  workImgStyle.width = '100%';
}